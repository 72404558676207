<template>
  <div class="simulation-hero-container">
    <div class="simulation-hero">
      <h1>
        Calculer le
        <strong>rendement d'un investissement locatif</strong>
      </h1>
      <SimulationFinForm class="form" />
    </div>
  </div>
</template>

<style lang="less" scoped>
.simulation-hero-container {
  .app-padding();
  background-color: var(--ds-color-primary-25);
  padding-top: 15px;
  padding-bottom: 10px;

  .simulation-hero {
    max-width: 1700px;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;

    .form {
      margin: 24px 0;
      box-shadow: rgba(0, 0, 0, 0.04) 0 2px 10px 0;
    }

    h1 {
      text-align: center;
      font-size: var(--ds-font-xl);
      margin: 0;

      strong {
        color: var(--ds-color-primary-100);
        font-weight: inherit;
      }
    }
  }

  @media @bp-lg {
    .simulation-hero {
      h1 {
        font-size: 33px;
        font-weight: var(--ds-weight-semi-bold);
      }
    }
  }

  @media @bp-xlg {
    padding-top: 50px;
  }
}
</style>
