<template>
  <div class="fin-form">
    <div class="tabs">
      <div
        v-for="(tab, index) in tabs"
        :ref="refs[tab.id]"
        :key="index"
        class="tab"
        :class="{ selected: currentTab && currentTab.id === tab.id }"
        @click="handleTabSelect(tab.id)"
      >
        <component
          :is="tab.icon"
          class="icon"
        />
        <div class="title">{{ tab.title }}</div>
      </div>
      <div
        ref="ink-bar"
        class="ink-bar"
        :style="inkBarStyle"
      />
    </div>
    <div
      v-if="currentTab"
      class="form-content"
    >
      <uikit-form-row>
        <uikit-form-cell title="Ville">
          <uikit-autocomplete-new
            id="city"
            name="city"
            placeholder="Tapez la ville ou code postal"
            class="city-autocomplete"
            :options="cities"
            :loading="loading"
            @update:modelValue="cityUpdateHandler"
            @onSelectOption="citySelectHandler"
          />
        </uikit-form-cell>
        <uikit-form-cell :title="inputPricePlaceholder">
          <uikit-input-price-new
            id="price"
            placeholder="0"
            name="price"
            variant="new"
            @update:modelValue="updateInputs"
          >
            <template #suffix>
              <span class="suffix">€{{ isSimulateEfficiency ? ' FAI' : '' }}</span>
            </template>
          </uikit-input-price-new>
        </uikit-form-cell>
      </uikit-form-row>

      <transition name="more-opts">
        <div
          v-show="isFinTabVisibleWithMore"
          :ref="refs.showMore"
        >
          <uikit-form-row class="u-mb-2xl">
            <uikit-form-cell title="Loyer">
              <uikit-input-price-new
                id="rent"
                placeholder="0"
                name="rent"
                :disabled="isUpdateInput('rent')"
                :loading="isUpdateInput('rent')"
                @update:modelValue="(value) => handleManualUpdate('rent', value)"
              >
                <template #suffix>
                  <span class="suffix">€ / mois</span>
                </template>
              </uikit-input-price-new>
            </uikit-form-cell>
            <uikit-form-cell title="Surface">
              <uikit-input-price-new
                id="surface"
                placeholder="0"
                name="surface"
                :disabled="isUpdateInput('surface')"
                :loading="isUpdateInput('surface')"
                @update:modelValue="(value) => handleManualUpdate('surface', value)"
              >
                <template #suffix>
                  <span class="suffix">
                    <span>m</span>
                    <sup>2</sup>
                  </span>
                </template>
              </uikit-input-price-new>
            </uikit-form-cell>
          </uikit-form-row>
        </div>
      </transition>
    </div>
    <uikit-button
      v-if="currentTab"
      class="submit"
      type="primary"
      :inactive="!meta.valid || loading || !hasValidCity"
      :loading="loading"
      @click="onSubmit"
    >
      {{ currentTab.submit }}
    </uikit-button>
  </div>
</template>

<script setup>
import { useForm } from 'vee-validate'
import { trackFinCreateSimulation } from '@/helpers/conversion/conversion'
import { debounce } from '@/helpers/utils'
import {
  UikitButton,
  UikitInputPriceNew,
  UikitCalculatorIcon,
  UikitHomeHeartIcon,
  UikitAutocompleteNew,
  UikitFormRow,
  UikitFormCell,
} from '@hz/ui-kit'
import { locationToQuery } from '../../composables/tub-api'

const config = useRuntimeConfig()
const route = useRoute()
const router = useRouter()

const statisticsApi = useStatisticsApi()

const refs = {
  'simulate-efficiency': ref(),
  'find-property': ref(),
  showMore: ref(),
}
const tabs = [
  {
    id: 'simulate-efficiency',
    title: "Simuler la rentabilité d'un bien",
    submit: 'Lancer la simulation',
    icon: UikitCalculatorIcon,
  },
  {
    id: 'find-property',
    title: 'Trouver un bien',
    submit: 'Rechercher',
    icon: UikitHomeHeartIcon,
  },
]

const id = route.query.tabId ?? tabs[0].id
const manualUpdatedInputs = ref([])
const updatingInputs = ref([])
const currentTab = ref(tabs.find((t) => t.id === id))
const cities = ref([])
const selectedCity = ref(null)
const loading = ref(false)
const showMore = ref(false)
const inkBarStyle = ref({
  left: 0,
  width: 0,
})

const isRequiredValidator = (value) => {
  if (
    value === null ||
    value === undefined ||
    value === '' ||
    (typeof value === 'string' && value.trim() === '')
  ) {
    return 'Ce champ est obligatoire.'
  }

  return true
}

const isFinTab = currentTab.value.id === 'simulate-efficiency'

const validationSchema = reactive({
  price: isRequiredValidator,
  city: (value) => {
    if (citiesLabel.value.some((item) => value === item)) {
      return true
    }

    return 'Veuillez sélectionner une ville dans la liste'
  },
  rent: isFinTab ? isRequiredValidator : undefined,
  surface: isFinTab ? isRequiredValidator : undefined,
})

const initialValues = {
  price: null,
  rent: null,
  surface: null,
  city: null,
}

const { handleSubmit, meta, values, setFieldValue } = useForm({
  validationSchema,
  initialValues,
})

const cityUpdateHandler = async (value) => {
  // ID case
  if (value.match(/^[a-f\d]{24}$/i)) {
    return
  }

  if (!value || !value.length) {
    selectedCity.value = value
  } else {
    loading.value = true
    cities.value = await useCitiesApi().getCities(value)
    loading.value = false
  }
}

const citySelectHandler = (value) => {
  setFieldValue('city', value.label)
  selectedCity.value = value
}

// Computed
const isUpdateInput = computed(() => (inputId) => updatingInputs.value.includes(inputId))
const isSimulateEfficiency = computed(() => currentTab.value.id === 'simulate-efficiency')
const inputPricePlaceholder = computed(() => (isSimulateEfficiency.value ? 'Prix du bien' : 'Budget max'))
const citiesLabel = computed(() => cities.value.map((c) => c.label))
const cityDetails = computed(() => cities.value.find((item) => item.label === values.city))
const hasValidCity = computed(() => values.city && cityDetails.value && cityDetails.value?.postalCode)
const isFinTabVisibleWithMore = computed(() => isSimulateEfficiency.value && showMore.value)

watch(currentTab, (newTab) => {
  updateInkBarStyle(newTab)
})

watch(isSimulateEfficiency, (isVisible) => {
  validationSchema.rent = isVisible ? isRequiredValidator : undefined
  validationSchema.surface = isVisible ? isRequiredValidator : undefined
})

watch(values, (value) => {
  if (
    (value && value.price && hasValidCity.value && !showMore.value) ||
    (value && value?.postalCode && values.price && showMore.value)
  ) {
    showMore.value = true
  }
})

// Actions
const updateInkBarStyle = (tab) => {
  if (!tab) return

  const tabNode = refs[tab.id].value?.[0]
  inkBarStyle.value = {
    left: `${tabNode ? tabNode.offsetLeft : 0}px`,
    width: `${tabNode ? tabNode.offsetWidth : 0}px`,
  }
}

const updateInputs = debounce(async () => {
  const updateSurface = !manualUpdatedInputs.value.includes('surface')
  const updateRent = !manualUpdatedInputs.value.includes('rent')

  if (!hasValidCity.value || !values.price || parseInt(values.price) < 1000) {
    setFieldValue('surface', updateSurface ? null : values.surface, { force: true })
    setFieldValue('rent', updateRent ? null : values.rent, { force: true })

    return
  }

  updatingInputs.value = [updateSurface ? 'surface' : '', updateRent ? 'rent' : '']
  const timeStart = new Date().getTime()
  const surfaceResponse = await statisticsApi.getAverageSurface({
    postalCode: selectedCity.value.postalCode,
    purchasePrice: values.price,
  })
  const surface = surfaceResponse?.surface

  if (!surface) {
    updatingInputs.value = []

    return
  }

  const rentResponse = await statisticsApi.getAverageRent({
    postalCode: selectedCity.value.postalCode,
    surfaceArea: surface,
  })
  const rent = rentResponse?.average

  const timeEnd = new Date().getTime()
  if (timeEnd - timeStart < 300) {
    await new Promise((resolve) => setTimeout(resolve, 300 - (timeEnd - timeStart)))
  }

  if (updateSurface && surface) {
    setFieldValue('surface', surface)
  }
  if (updateRent && rent) {
    setFieldValue('rent', rent)
  }
  updatingInputs.value = []
}, 300)

const handleTabSelect = (tabId) => {
  currentTab.value = tabs.find((tab) => tab.id === tabId)
}

const onSubmit = handleSubmit((formValues) => {
  if (loading.value || !hasValidCity.value) {
    return
  }

  switch (currentTab.value.id) {
    case 'simulate-efficiency':
      handleSimulationSubmit(formValues)
      break
    case 'find-property':
      handlePropertySubmit()
      break
  }
})

const handleSimulationSubmit = async (formValues) => {
  trackFinCreateSimulation(selectedCity.value.locality, selectedCity.value.postalCode)

  window.location.href = `${config.public.appBaseUrl}/dashboard/future-investor?ob_ville=${selectedCity.value.locality}&ob_code_postal=${selectedCity.value.postalCode}&ob_prix=${formValues.price}&ob_loyer=${formValues.rent}&ob_surface=${formValues.surface}`
}

const handlePropertySubmit = async () => {
  await router.push({
    name: 'biens-rentables-custom-search',
    query: {
      locations: locationToQuery({
        cp: `${selectedCity.value.postalCode}`,
        ville: `${selectedCity.value.locality}`,
        distance: selectedCity.value.hasMultiplePostalCodes ? 'all-city' : 0,
      }),
      prixMax: values.price,
      sorting: '-rendement',
    },
  })
}

const handleWindowResize = () => {
  updateInkBarStyle(currentTab.value)
}

const handleManualUpdate = (inputId, value) => {
  const index = manualUpdatedInputs.value.indexOf(inputId)
  if (value && value > 0) {
    if (index < 0) {
      manualUpdatedInputs.value.push(inputId)
    }
  } else {
    manualUpdatedInputs.value.splice(index, 1)
  }
}

onBeforeMount(() => {
  window.addEventListener('resize', handleWindowResize)
})

onMounted(async () => {
  handleWindowResize()
})

onBeforeUnmount(() => {
  window.removeEventListener('resize', handleWindowResize)
})
</script>

<style lang="less" scoped>
.fin-form {
  width: 100%;
  min-height: 340px;
  background-color: var(--ds-color-white-100);
  border: 2px solid #f4f7fb;
  border-radius: 8px;
  padding: 0 47px 47px;
  display: flex;
  flex-direction: column;

  @media @bp-lg {
    width: 715px;
  }

  .submit {
    margin: auto;
  }

  .form-content {
    width: 100%;
    padding: 28px 14px 0;

    .form-line {
      padding-bottom: 28px;
      display: grid;
      grid-gap: 28px;
      grid-template-columns: 1fr;
      grid-template-rows: 1fr 1fr;
    }

    @media @bp-lg {
      padding: 28px 0 0;

      .form-line {
        grid-gap: 14px;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr;
      }
    }
  }

  .tabs {
    position: relative;
    display: flex;
    justify-content: space-evenly;
    border-bottom: 3px solid #f4f7fb;

    font-size: 14px;

    @media @bp-lg {
      font-size: 18px;
      white-space: nowrap;
    }

    .tab {
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      padding: 24px 0;
      width: 50%;
      color: var(--ds-color-gray-50);
      transition: all 0.2s ease;

      @media @bp-md-down {
        flex-direction: column;
        justify-content: flex-start;

        .title {
          margin-left: 0;
          margin-top: 14px;
        }
      }

      .icon {
        width: 24px;
        height: 22px;
        flex-shrink: 0;
      }

      .title {
        margin-left: 14px;
        text-align: center;
        font-weight: var(--ds-weight-semi-bold);
      }

      &.selected,
      &:hover {
        cursor: pointer;
        color: var(--ds-color-primary-100);
      }
    }

    .ink-bar {
      position: absolute;
      width: 0;
      height: 5px;
      bottom: -3px;
      background: var(--ds-color-primary-100);
      border-radius: 30px 30px 0 0;
      transition:
        left 0.3s,
        width 0.3s;
    }
  }

  @media @bp-mobile {
    padding: 0 0 14px;

    .tabs .tab {
      padding: 12px 0;
    }
  }

  .block-city-autocomplete {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    .label-city-autocomplete {
      font-size: var(--ds-font-md);
      font-weight: var(--ds-weight-semi-bold);
      margin-bottom: 12px;
    }
  }
}

.more-opts-enter-active {
  transition: opacity 0.3s ease;
}

.more-opts-leave-active {
  transition: opacity 0.3s ease;
}

.more-opts-enter-from,
.more-opts-enter-to {
  opacity: 0;
}
</style>
