<template>
  <div id="simulation" :key="tabKey">
    <SimulationHero />
    <SectionsPartners id="partners" class="partners" />
    <nuxt-lazy-hydrate when-visible>
      <LazySectionsFeatures
        id="features"
        :special-features="specialFeatures"
        class="no-space"
        context="simulator"
        @pageChange="updateKey"
      />
    </nuxt-lazy-hydrate>
    <nuxt-lazy-hydrate when-visible>
      <LazySectionsArguments id="arguments" class="no-space" />
    </nuxt-lazy-hydrate>
    <nuxt-lazy-hydrate when-visible>
      <lazy-home-invest-cities class="section" />
    </nuxt-lazy-hydrate>
    <nuxt-lazy-hydrate when-visible>
      <lazy-simulation-cards-link id="cards-link" class="section" />
    </nuxt-lazy-hydrate>
    <nuxt-lazy-hydrate when-visible>
      <LazySectionsExpertise id="expertise" :displayReceipts="true" class="no-space" />
    </nuxt-lazy-hydrate>
    <nuxt-lazy-hydrate when-visible>
      <lazy-sections-blog-articles
        id="news-section"
        topCtaLink="https://horiz.io/calcul-rendement-locatif"
        :content="blogContent"
        title="En savoir plus sur le <strong>rendement d’un investissement locatif</strong>"
      />
    </nuxt-lazy-hydrate>
    <nuxt-lazy-hydrate when-visible>
      <lazy-sections-f-a-q id="faq" :faq="faq" class="no-space" />
    </nuxt-lazy-hydrate>
    <nuxt-lazy-hydrate when-visible>
      <lazy-sections-customer-reviews title="Des milliers d'investisseurs immobiliers nous font confiance." />
    </nuxt-lazy-hydrate>
  </div>
</template>

<script setup>
const config = useRuntimeConfig()
const tabKey = ref(null)

const { data: articles } = await useLazyAsyncData(() => useCmsApi().getLandingSimulationArticles(), {
  default: () => [],
})

const blogContent = computed(() => ({
  articles: articles.value,
}))

const faq = [
  {
    title: 'Pourquoi investir dans l’immobilier locatif ?',
    content:
      'Pour assurer vos beaux jours, vous constituer un patrimoine et le transmettre à vos enfants, petits enfants… Mais aussi vous créer un revenu complémentaire, réduire vos impôts ou bien placer votre épargne autrement.',
  },
  {
    title: 'Qu’est-ce que le taux de rendement ?',
    content:
      'Le taux de rendement est le ratio entre la part de vos revenus locatifs et le montant que vous avez investi. C’est un indicateur qui vous permet d’évaluer la pertinence et la performance de votre future acquisition.',
  },
  {
    title: 'Rendement locatif : comment le calculer ?',
    content:
      'Il existe 2 types de rendement locatif vous permettant d’évaluer la pertinence de votre investissement : <ul><li>- Le rendement locatif brut permet d’avoir une estimation de la rentabilité de votre bien</li> <li>- Le rendement locatif net donne une estimation beaucoup plus précise de votre rentabilité (il inclut par exemple les taxes et charges)</li></ul>',
  },
  {
    title: 'Qu’est-ce qu’un bon taux de rendement locatif ?',
    content:
      "Un taux de rendement égal ou supérieur à 10 % brut est un très <a href='https://horiz.io/calcul-rendement-locatif/quel-est-un-bon-taux-de-rendement-locatif'>bon taux de rentabilité locative</a> et garantit un rendement net supérieur à celui d’autres placements plus classiques. Seul un calcul détaillé vous permettra de connaître le meilleur taux de rendement espéré en fonction de votre projet et de votre situation personnelle.",
  },
  {
    title: 'Quel est le rendement locatif moyen ?',
    content: 'Selon vos objectifs cela peut varier : le rendement locatif moyen se situe entre 5 % et 10 %.',
  },
  {
    title: 'Comment simuler le rendement ?',
    content:
      "Notre simulateur de rendement est là pour vous aider ! Il vous suffit de renseigner certains paramètres concernant votre projet d’<a href='https://horiz.io/investissement-locatif/investissement-locatif-4-cles-pour-le-rentabiliser'>investissement locatif</a> afin de simuler le meilleur rendement en fonction de vos critères et vos objectifs.",
  },
  {
    title: 'Quelles sont les principales fonctionnalités de votre simulateur de rentabilité locative ?',
    content:
      'Notre simulateur de rentabilité vous permet de faire un investissement rentable et/ou de vérifier la rentabilité d’un bien. Il est fait pour vous aider et vous orienter dans votre prise de décision finale.  Vous avez accès à un rapport de rentabilité simplifié et détaillé (également exportable sous format PDF). Vous visualisez alors ce que cela vous coûtera par mois, la rentabilité de votre bien, vos impôts mais aussi comment gérer votre emprunt (tableau d’amortissement). Le simulateur vous permet également de calculer votre éventuelle plus-value à la revente. Bien entendu, toutes vos informations sont modifiables à tout moment !',
  },
  {
    title: 'Puis-je faire une simulation de rentabilité avec des biens en meublé et en nu ?',
    content:
      'Tout à fait ! Vous pouvez simuler votre rentabilité autant de fois que vous le souhaitez en changeant vos critères (régime de location meublé ou nu ou autre, budget, fiscalité, durée d’emprunt, etc) afin de vous permettre de déterminer la situation idéale en fonction de votre profil.',
  },
  {
    title: 'Rentabilité nette d’un bien immobilier : comment la calculer ?',
    content:
      "Pour calculer la rentabilité nette d’un bien immobilier, partez de sa rentabilité brute et prenez en compte toutes vos <a href='https://horiz.io/gestion-immobiliere/le-guide-sur-le-decompte-des-charges'>charges</a> ainsi que les frais que vous aurez à payer en tant que <a href='https://horiz.io/gestion-immobiliere/locataire-et-proprietaire-voyons-qui-paie-quoi'>propriétaire</a> bailleur. N’oubliez aucun frais pour avoir une estimation complète. Notre simulateur peut vous y aider !",
  },
  {
    title: 'Comment calculer le cash-flow ?',
    content:
      "Très bonne question ! L’une des principales contraintes lors de votre <a href='https://horiz.io/calcul-rendement-locatif/comment-calculer-le-cash-flow-d-un-investissement-locatif'>calcul de cash-flow</a> est de n’oublier aucune dépense afin d’arriver à déterminer son évolution dans le temps. Pour vous simplifier la vie, notre simulateur détermine votre cash-flow à votre place (avec en prime le rendement net).",
  },
  {
    title: "Comment avoir un cash-flow positif avec l'immobilier ?",
    content:
      "C’est le graal de tout <a href='https://horiz.io/investissement-locatif/devenir-investisseur-immobilier-nos-conseils'>investisseur immobilier</a>. Concrètement, votre cash-flow est positif lorsque les loyers perçus dépassent les charges que vous avez à payer. Pour ce faire, vous pouvez visualiser la répartition de vos charges et recettes dans notre rapport détaillé, afin de déterminer quelles seront vos charges les plus conséquentes. Par exemple : si vous vous rendez compte que votre mensualité d’emprunt est trop élevée, il faudra donc allonger la durée de votre prêt. Cela vous aide dans vos prises de décisions financières !",
  },
  {
    title: 'Qu’est-ce que le TRI (Taux de Rendement Interne) ?',
    content:
      "C’est un indicateur de rentabilité financière vous permettant de comparer n’importe quels investissements entre eux. Dans le cas de l’investissement locatif, il permet d’évaluer si votre investissement est rentable ou non. Il prend en compte différents flux tels que l’apport, les frais de dossier d’emprunt, <a href='https://horiz.io/investissement-immobilier/comment-faire-baisser-les-frais-de-notaire'>frais de notaire</a>, montant de votre emprunt, revenus des loyers et impôts.",
  },
  {
    title: 'Comment calculer le TRI d’un investissement ?',
    content:
      "Prenez en compte un scénario complet jusqu’à la revente de votre bien (au bout de X années). Notre simulateur vous propose 2 façons de le calculer : <ul><li><a href='https://horiz.io/calcul-rendement-locatif/qu-est-ce-que-le-tri-taux-de-rendement-interne'>TRI</a> (auto) : calcul automatique par défaut qui prend en compte la revente de votre bien au bout de 10 ans à un prix net vendeur correspondant à une augmentation annuelle de 1 %.</li> <li>TRI (personnalisé) : vous indiquez votre estimation du prix net vendeur de revente dans les options avancées du calculateur ainsi que l'année de revente.</li></ul>",
  },
  {
    title: 'Qu’est-ce que la VAN (Valeur Actuelle Nette) ?',
    content:
      "La <a href='https://horiz.io/calcul-rendement-locatif/qu-est-que-la-van-valeur-actuelle-nette-definition-et-calcul'>VAN</a> est un indicateur financier qui permet de vous orienter dans votre prise de décision dans le cas d’un investissement. Il représente ce qu’un investissement locatif peut vous apporter de plus par rapport à un placement comme un livret A ou une assurance-vie. L’objectif est donc de connaître la viabilité de votre projet.",
  },
  {
    title: 'Comment calculer la VAN d’un investissement ?',
    content:
      'Vous êtes prêt ? La première étape est de réaliser une étude complète de votre projet d’investissement locatif. La VAN correspond à : Total des recettes de votre bien - Investissement Si votre VAN est positive, alors le projet est rentable. Ce qui est intéressant, c’est de calculer et comparer la VAN de votre investissement à celle d’un autre placement (tel que le livret A ou une assurance-vie) sur une période donnée : la VAN la plus élevée correspond à l’investissement le plus rentable.',
  },
]

const specialFeatures = [
  {
    data: {
      title: '<div class="space-around">Votre projet</div>',
      description:
        'Vous voulez investir sans savoir quel type d’investissement faire pour être <span class="bold">le plus rentable.</span>',
      class: 'bg-purple-light',
    },
    img: {
      class: 'search',
      src: '/imgs/pages/feature-search.webp',
      alt: 'Votre projet',
      width: '60',
      height: '60',
    },
  },
  {
    data: {
      title: '<div class="space-around">Le bien idéal',
      description:
        "Nous déterminons quel serait le <span class='bold'>meilleur choix d'investissement</span> pour vous en fonction de votre budget et votre situation personnelle.",
      class: 'bg-cyan-light',
    },
    img: {
      class: 'impot',
      src: '/imgs/pages/feature-impots.webp',
      alt: 'Le bien idéal',
      width: '54',
      height: '54',
    },
  },
  {
    data: {
      title: 'Des annonces<br />immobilières adaptées',
      description:
        "Nous vous proposons une sélection de <span class='bold'>biens correspondant à votre projet idéal</span> parmi les annonces dont nous avons connaissance.",
      class: 'bg-pink-light',
    },
    img: {
      class: 'bail',
      src: '/imgs/pages/feature-bail.webp',
      alt: 'Des annonces immobilières adaptées',
      width: '62',
      height: '62',
    },
  },
  {
    data: {
      title: 'Des experts à<br />votre service',
      description:
        "Avocats, comptables, fiscalistes, etc., <span class='bold'>contactez nos experts</span> et obtenez des réponses claires à vos questions.",
      class: 'bg-orange-light',
    },
    img: {
      class: 'image expert',
      src: '/imgs/pages/feature-expert.webp',
      alt: 'Des experts à votre service',
      width: '74',
      height: '74',
    },
  },
]

// Actions
const updateKey = (e) => {
  tabKey.value = e.key
  window.scrollTo({
    top: 0,
    behavior: 'smooth',
  })
}

onMounted(async () => {
  await useTracking().trackAfterMounted('[SIMULATEUR_LP] Accès Landing')
})

// Metas
const title = 'Calcul Rendement Locatif : simulation, investissement, avis | Horiz.io'

useMeta({
  title,
  description:
    "Calculez le rendement net d'un investissement locatif immobilier, impôts compris. Simulateur complet et rapide. Trouvez l'investissement idéal à réaliser.",
  image: 'Horizio-rendement-locatif.jpg',
  imageAlt: "Horiz.io, calculer le rendement d'un investissement locatif",
  url: `${config.public.hpBaseUrl}/rendement-locatif`,
  appleItunesApp: 'app-id=1596721394',
})

useHead({
  title,
  link: [
    {
      rel: 'canonical',
      href: `${config.public.hpBaseUrl}/rendement-locatif`,
    },
  ],
})

useJsonld([
  {
    '@context': 'https://schema.org',
    '@type': 'Organization',
    name: 'Horiz',
    alternateName: 'Rendement Locatif',
    url: `${config.public.hpBaseUrl}/rendement-locatif`,
    logo: `${config.public.hpBaseUrl}/imgs/logo.png`,
  },
  {
    '@context': 'https://schema.org/',
    '@type': 'WebPage',
    name: 'Horiz',
    speakable: {
      '@type': 'SpeakableSpecification',
      xpath: ['/html/head/title', "/html/head/meta[@name='description']/@content"],
    },
    url: `${config.public.hpBaseUrl}/rendement-locatif`,
  },
  {
    '@context': 'http://www.schema.org',
    '@type': 'Product',
    name: 'Horiz',
    brand: ' Horiz',
    image: `${config.public.hpBaseUrl}/imgs/logo.png`,
    description: "/html/head/meta[@name='description']/@content",
    aggregateRating: {
      '@type': 'aggregateRating',
      ratingValue: '5',
      reviewCount: '62',
      bestRating: '5 ',
    },
  },
  {
    '@context': 'http://schema.org',
    '@type': 'BreadcrumbList',
    itemListElement: [
      {
        '@type': 'ListItem',
        position: '1',
        name: 'Home',
        item: config.public.hpBaseUrl,
      },
      {
        '@type': 'ListItem',
        position: '2',
        name: 'Rendement Locatif',
        item: `${config.public.hpBaseUrl}/rendement-locatif`,
      },
    ],
  },
])
</script>

<script>
export default {
  beforeMount() {
    const hash = this.$route.hash
    if (hash && hash.indexOf('villevalue') !== -1) {
      const redirectUrl = new URL(`${this.$config.public.appBaseUrl}/dashboard/future-investor`)
      const hashWithoutKcParameters = hash.split('&')[0]
      redirectUrl.searchParams.append(
        'computeParameters',
        hashWithoutKcParameters.replace('#', '').replace('/', ''),
      )

      Object.keys(this.$route.query)
        .filter((queryKey) => queryKey.startsWith('utm_'))
        .forEach((queryKey) => redirectUrl.searchParams.append(queryKey, this.$route.query[queryKey]))

      location.href = redirectUrl.toString()
    }
  },
}
</script>

<style lang="less" scoped>
.partners {
  background-color: var(--ds-color-primary-25);
}

.section {
  margin: 100px auto !important;
  padding-top: 0;
  padding-bottom: 0;

  &#features,
  &#faq {
    margin-top: 42px !important;
  }

  &#news-section {
    margin-bottom: 42px !important;
  }

  &#arguments,
  &#blog {
    padding: 40px 0;
  }

  @media @bp-md-down {
    margin: 60px auto !important;
  }
}

#arguments,
#blog {
  background: var(--ds-color-primary-25);
}
#cards-link {
  background: var(--ds-color-primary-100);
}

:deep(.faq-container) {
  padding: 0 30px;
}
</style>
